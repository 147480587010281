<template>
  <div class="view-terms py-5 text-light">
    <div class="container pb-5">
      <h1 class="text-center mb-5 px-5">
        <router-link :to="{ name: 'index' }">
          <img class="img-fluid w-25" src="@/assets/images/logo.png" />
        </router-link>
      </h1>
      <h1 class="mb-5">Termos legais</h1>

      <div class="text-secondary"></div>
    </div>
  </div>
</template>
